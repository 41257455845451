<template>
    <v-app id="create-client">
        <v-sheet height="100%" class="grey lighten-4 pb-10">
            <div class="ma-6">
                <v-btn
                    class="close_btn_custom"
                    icon
                    to="/appointment"
                >
                    <v-icon color="black" large>mdi-close</v-icon>
                </v-btn>

                <v-btn
                    absolute
                    right
                    x-large
                    color="#101928"
                    dark
                    height="48px"
                    width="160px"
                    class="mr-2 text-capitalize"
                    style="letter-spacing: 0.5px;"
                >
                    Save
                </v-btn>
            </div>

            <div class="d-flex justify-center">
                <h2 style="font-size: 1.7rem;letter-spacing: 0.6px;">Add a new client</h2>
            </div>

            <v-sheet max-width="1000" class="mx-auto pt-8" color="transparent" elevation="0">
                <v-row>
                    <v-col cols="8">
                        <v-card class="rounded-lg pa-">
                            <h3 class="py-4 px-6">Basic info</h3>
                            <v-divider></v-divider>
                            <v-card-text class="px-6">
                                <div class="d-flex flex-column">
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <label class="dialog_label">First name</label>
                                            <v-text-field
                                                solo
                                                placeholder="Enter client's first name"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                        >
                                            <label class="dialog_label">Last name</label>
                                            <v-text-field
                                                solo
                                                placeholder="Enter client's last name"
                                            ></v-text-field>
                                        </v-col>                                        

                                        <v-col
                                            cols="12"
                                            sm="6"
                                            class="pt-0"
                                        >
                                            <label class="dialog_label">Mobile number</label>
                                            <vue-tel-input-vuetify class="custom_solo_field" placeholder="Enter client's mobile number" v-model="yourValue" solo></vue-tel-input-vuetify>
                                        </v-col>

                                        <v-col
                                            cols="12"
                                            sm="6"
                                            class="pt-0"
                                        >
                                            <label class="dialog_label">Email address</label>
                                            <v-text-field
                                                solo
                                                placeholder="Enter client's email address"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col
                                            cols="12"
                                            sm="12"
                                            class="pt-0"
                                        >
                                            <label class="dialog_label">Gender</label>
                                            <v-select
                                                :items="genders_list"
                                                solo
                                                placeholder="Select an option"
                                            ></v-select>
                                        </v-col>

                                        <v-col
                                            cols="12"
                                            sm="6"
                                            class="pt-0"
                                        >
                                            <label class="dialog_label">Date of birth</label>
                                            <v-menu
                                                ref="dob_datepicker"
                                                v-model="dob_datepicker"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="formatted_dob_date"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        solo
                                                        placeholder="Day and month"
                                                        append-icon="mdi-menu-down"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="selected_dob"
                                                    no-title
                                                    scrollable
                                                    @change="save"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="6"
                                            class="pt-0"
                                        >
                                            <label class="dialog_label">Year</label>
                                            <v-text-field
                                                solo
                                                placeholder="Year"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card-text>
                        </v-card>

                        <v-card class="rounded-lg mt-7">
                            <h3 class="pt-4 px-6">Client notes</h3>
                            <p class="pb-4 px-6 text-body-2 mb-0">Client notes will only be visible to you and staff members.</p>
                            <v-divider></v-divider>
                            <v-card-text class="px-6 pb-0">
                                <div class="d-flex flex-column">
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            class="pb-0"
                                        >
                                            <label class="dialog_label">Note</label>
                                            <v-textarea
                                                solo
                                                placeholder="E.g. allergy to shampoos with sodium"
                                                auto-grow
                                                rows="4"
                                                row-height="27"
                                            ></v-textarea>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            class="pt-0"
                                        >
                                            <v-checkbox
                                                label="Display on all bookings"
                                                color="primary"
                                                class="mt-0 pt-0"
                                            ></v-checkbox>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card-text>
                        </v-card>

                        <v-card class="rounded-lg mt-7">
                            <h3 class="py-4 px-6">Additional info</h3>
                            <v-divider></v-divider>
                            <v-card-text class="px-6 pb-0">
                                <div class="d-flex flex-column">
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                        >
                                            <label class="dialog_label">Additional phone number</label>
                                            <vue-tel-input-vuetify class="custom_solo_field" placeholder="Enter client’s additional number" v-model="yourValue" solo></vue-tel-input-vuetify>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                            class="pt-0"
                                        >
                                            <label class="dialog_label">Client source</label>
                                            <v-select
                                                :items="['Default Customer']"
                                                value="Default Customer"
                                                solo
                                                placeholder="Select an option"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card-text>
                        </v-card>

                        <v-card class="rounded-lg mt-7">
                            <h3 class="py-4 px-6">Address</h3>
                            <v-divider></v-divider>
                            <v-card-text class="px-6 pb-6">
                                <div class="d-flex flex-column">
                                    <v-row>
                                        <v-col
                                            cols="12"
                                            sm="12"
                                        >
                                            <a style="font-size:18px"><v-icon color="#1976d2" class="text-h5">mdi-plus-circle-outline</v-icon> Add new address</a>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-card-text>
                        </v-card>

                    </v-col>

                    <v-col cols="4">
                        <v-card class="rounded-lg pa-">
                            <h3 class="pt-4 px-6">Notifications</h3>
                            <p class="px-6 pt-1 pb-4 text-body-1" style="letter-spacing: 0.3px !important;">Choose how you'd like to keep this client up to date about their appointments and sales, like vouchers and paid plans.</p>

                            <v-divider></v-divider>

                            <h4 class="py-4 px-6 font-weight-medium">Client notifications</h4>
                            <div class="d-flex flex-column px-6 pb-2">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        class="pt-0"
                                    >
                                        <v-switch
                                            class="custom_switch"
                                            :v-model="true"
                                            label="Send email notifications"
                                            color="#00a36d"
                                            inset
                                        ></v-switch>

                                        <v-switch
                                            class="custom_switch mt-0 pt-0"
                                            :v-model="true"
                                            label="Send text notifications"
                                            color="#00a36d"
                                            inset
                                        ></v-switch>
                                    </v-col>
                                </v-row>
                            </div>

                            <v-divider></v-divider>

                            <h4 class="py-4 px-6 font-weight-medium">Marketing notifications</h4>
                            <div class="d-flex flex-column px-6 pb-2">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        class="pt-0"
                                    >
                                        <v-switch
                                            class="custom_switch"
                                            :v-model="true"
                                            label="Client accepts marketing notifications"
                                            color="#00a36d"
                                            inset
                                        ></v-switch>
                                    </v-col>
                                </v-row>
                            </div>

                            <v-divider></v-divider>

                            <div class="px-6 pt-6">
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        class="pt-0 pb-0"
                                    >
                                        <label class="dialog_label">Preferred language</label>
                                        <v-select
                                            :items="preferred_langs"
                                            item-text="label"
                                            item-value="value"
                                            solo
                                            placeholder="Select language"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-sheet>
            
        </v-sheet>
        

        <v-divider></v-divider>
        
       
    </v-app>
</template>

<script>
import moment from 'moment'

export default {
    name: "Client",

    data: () => ({
        country_prefix : [
            { label: 'United States 🇺🇸 +1', value: '+1' },
            { label: 'United Kingdom 🇬🇧 +44', value: '+44' },
            { label: 'Canada 🇨🇦 +1', value: '+1' },
            { label: 'United Arab Emirates 🇦🇪 +971', value: '+971' },
            { label: 'Afghanistan 🇦🇫 +93', value: '' },
            { label: 'Åland Islands 🇦🇽 +358', value: '+358' },
            { label: 'Albania 🇦🇱 +355', value: '+355' },
            { label: 'Algeria 🇩🇿 +213', value: '+213' },
            { label: 'American Samoa 🇦🇸 +1 684', value: '+1 684' },
            { label: 'Andorra 🇦🇩 +376', value: '+376' },
            { label: 'Angola 🇦🇴 +244', value: '+244' },
            { label: 'Anguilla 🇦🇮 +1 264', value: '+1 264' },
            { label: 'Antarctica 🇦🇶 +672', value: '+672' }
        ],
        notifications : [
            { label: "Don't send notifications", value: '0' },
            { label: "Email", value: 'email' },
            { label: "SMS", value: 'sms' },
            { label: "Email & SMS", value: 'both' },
        ],
        preferred_langs: [
            { label: "Use provider language (English)", value: '0' },
            { label: "Bulgarian (български)", value: 'bg' },
            { label: "Czech (čeština)", value: 'cs' },
            { label: "Danish (dansk)", value: 'da' },
            { label: "German (Deutsch)", value: 'de' },
            { label: "Greek (Ελληνικά)", value: 'el' },
            { label: "English (English)", value: 'en' },
            { label: "Spanish (español)", value: 'es' },
        ],
        select_notify: 'both',
        select_country: '+1',
        select_lang: '',
        yourValue : '',
        genders_list : [
            'Select an option',
            'Female',
            'Male',
            'Non-binary',
            'Prefer not to say'
        ],
        dob_datepicker : false,
        selected_dob : ''
        //new Date().toISOString().substr(0, 10),
    }),
    computed: {
        formatted_dob_date() {
            return this.selected_dob ? moment(this.selected_dob).format('MMMM D') : "";
        },
        // select_country() {
        //     return parseInt(this.select_country);
        // }
    },
    methods: {
        save (date) {
            console.log("date", date)
            this.$refs.dob_datepicker.save(date)
        },
    }
}
</script>